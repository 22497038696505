export const userListByDesignationApi = 'user/users-by-designation-id'
export const tariffProductApi = '/world-trade-directory-management/export-import/product'

// application Info Routes......
const countryInformationManagement = '/world-trade-directory-management/country-information-management/'
export const countryInformationManagementList = countryInformationManagement + 'list'
export const countryInformationManagementStore = countryInformationManagement + 'store'
export const countryInformationManagementUpdate = countryInformationManagement + 'forward'
export const countryInformationManagementToggle = countryInformationManagement + 'toggle-status'
export const tariffPortsLstApi = countryInformationManagement + 'ports'

const tariffProfileManagement = '/world-trade-directory-management/tariff-profile-management/'
export const tariffProfileManagementList = tariffProfileManagement + 'list'
export const tariffProfileManagementStore = tariffProfileManagement + 'store'
export const tariffProfileManagementUpdate = tariffProfileManagement + 'update'
export const tariffProfileManagementToggle = tariffProfileManagement + 'toggle-status'

const wtoRtoInfoManagement = '/world-trade-directory-management/wto-rto-info-management/'
export const wtoRtoInfoManagementList = wtoRtoInfoManagement + 'list'
export const wtoRtoInfoManagementStore = wtoRtoInfoManagement + 'store'
export const wtoRtoInfoManagementUpdate = wtoRtoInfoManagement + 'update'
export const wtoRtoInfoManagementToggle = wtoRtoInfoManagement + 'toggle-status'

const bilateralTradeManagement = '/world-trade-directory-management/bilateral-trade-management/'
export const bilateralTradeManagementList = bilateralTradeManagement + 'list'
export const bilateralTradeManagementStore = bilateralTradeManagement + 'store'
export const bilateralTradeManagementUpdate = bilateralTradeManagement + 'update'
export const bilateralTradeManagementToggle = bilateralTradeManagement + 'toggle-status'

const exportManagement = '/world-trade-directory-management/export-management/'
export const exportManagementList = exportManagement + 'list'
export const exportManagementStore = exportManagement + 'store'
export const exportManagementUpdate = exportManagement + 'update'
export const exportManagementToggle = exportManagement + 'toggle-status'

const importManagement = '/world-trade-directory-management/import-management/'
export const importManagementList = importManagement + 'list'
export const importManagementStore = importManagement + 'store'
export const importManagementUpdate = importManagement + 'update'
export const importManagementToggle = importManagement + 'toggle-status'
