<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
    <b-overlay :show="loading">
      <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
        <b-row>
        <b-col lg="6" sm="12">
            <ValidationProvider name="Targeted Country" vid="targeted_country" rules="required|min_value:1">
              <b-form-group
                class="row"
                label-for="targeted_country"
                slot-scope="{ valid, errors }"
                >
                  <template v-slot:label>
                    {{ $t('tradeTariffApp.targeted_country')}} <span class="text-danger">*</span>
                  </template>
                <b-form-select
                  plain
                  v-model="form.targeted_country"
                  :options="tradeTariffCountryList"
                  id="targeted_country"
                  :state="errors[0] ? false : (valid ? true : null)"
                  >
                  <template v-slot:first>
                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="6" sm="12">
            <ValidationProvider name="Associated Country" vid="associated_country" rules="required|min_value:1">
              <b-form-group
                class="row"
                label-for="associated_country"
                slot-scope="{ valid, errors }"
                >
                  <template v-slot:label>
                    {{ $t('tradeTariffApp.associated_country')}} <span class="text-danger">*</span>
                  </template>
                <b-form-select
                  plain
                  v-model="form.associated_country"
                  :options="tradeTariffCountryList"
                  id="associated_country"
                  :state="errors[0] ? false : (valid ? true : null)"
                  >
                  <template v-slot:first>
                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="6" sm="12">
              <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="required|min_value:1">
            <b-form-group
              label-for="fiscal_year_id"
              slot-scope="{ valid, errors }"
              >
                <template v-slot:label>
                  {{ $t('globalTrans.fiscal_year') }} <span class="text-danger">*</span>
                </template>
                <b-form-select
                  plain
                  id="fiscal_year_id"
                  :options="fiscalYearList"
                  v-model="form.fiscal_year_id"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                  <template v-slot:first>
                    <b-form-select-option :value='0'>{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
              </ValidationProvider>
          </b-col>
          <b-col lg="6" sm="12">
          </b-col>
        <b-col lg="6" sm="12">
            <ValidationProvider name="Total Import" vid="total_import" rules="required">
              <b-form-group
                class="row"
                label-for="total_import"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{ $t('tradeTariffApp.total_import')}}<span class="text-danger">*</span>
                </template>
                <b-form-input
                  v-model="form.total_import"
                  id="total_import"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                </b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        <b-col lg="6" sm="12">
            <ValidationProvider name="Total Export" vid="total_export" rules="required">
              <b-form-group
                class="row"
                label-for="total_export"
                slot-scope="{ valid, errors }"
              >
                <template v-slot:label>
                {{ $t('tradeTariffApp.total_export')}}<span class="text-danger">*</span>
                </template>
                <b-form-input
                  v-model="form.total_export"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                  id="total_export"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                </b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="12" sm="12">
              <ValidationProvider name="bilateral_trade_details" vid="bilateral_trade_details" v-slot="{ errors }">
                <b-form-group
                    class="row"
                    label-for="bilateral_trade_details"
                >
                    <template v-slot:label>
                        {{$t('tradeTariffApp.bilateral_trade_details')}}
                    </template>
                    <b-form-textarea
                        v-model="form.bilateral_trade_details"
                        rows="1"
                        max-rows="2"
                        :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-textarea>
                    <div class="invalid-feedback">
                        {{ errors[0] }}
                    </div>
                </b-form-group>
              </ValidationProvider>
          </b-col>
          <b-col lg="12" sm="12">
            <ValidationProvider name="Attachment" vid="attachment_main" :rules="attachmentReq">
              <b-form-group slot-scope="{ valid, errors }" label-for="attachment_main" >
                <template v-slot:label>
                    {{$t('globalTrans.attachment')}}<span class="text-danger">* {{ $t('externalTradeTraiff.attachment_type') }}</span>
                </template>
                <div class="d-flex">
                  <b-form-file accept=".pdf, .docx, .doc" id="attachment_main" v-model="form.file"
                    @change="onFileChange" :state="errors[0] ? false : (valid ? true : null)"
                    :placeholder="$t('globalTrans.attachment_placeholder')"
                    :drop-placeholder="$t('externalTradeTraiff.attachment_drop_placeholder')"></b-form-file>
                </div>
                <div class="invalid-feedback d-block">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
        <b-row class="text-right">
          <b-col>
              <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
              <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-form')">{{ $t('globalTrans.cancel') }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </ValidationObserver>
</template>
<script>
import RestApi, { tradeTariffServiceBaseUrl } from '@/config/api_config'
import { bilateralTradeManagementStore } from '../../api/routes'
export default {
  name: 'Form',
  props: ['id'],
  data () {
    return {
      tradeTariffServiceBaseUrl: tradeTariffServiceBaseUrl,
      valid: null,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      unitLoading: false,
      attachmentReq: 'required',
      form: {
        targeted_country: 0,
        associated_country: 0,
        total_import: '',
        total_export: '',
        fiscal_year_id: 0,
        bilateral_trade_details: '',
        attachment: ''
      }
    }
  },
  created () {
    if (this.id) {
      const tmp = this.getEditInfo()
      this.form = tmp
      this.form.attachment = ''
      this.attachmentReq = ''
    }
  },
  computed: {
    loading: function () {
      return this.$store.state.commonObj.loading
    },
    tradeTariffCountryList: function () {
      return this.$store.state.TradeTariffService.commonObj.tradeTariffCountryList.filter(item => item.status === 1)
    },
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    }
  },
  watch: {
    currentLocale: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.localizeSeaPortsList(newVal)
      }
    }
  },
  methods: {
    onFileChange (event) {
          const input = event.target
          if (input.files && input.files[0]) {
            const reader = new FileReader()
            reader.onload = (e) => {
              this.form.attachment = e.target.result
            }
            reader.readAsDataURL(input.files[0])
          } else {
            this.form.attachment = ''
          }
    },
    async saveUpdate () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
      const formData = this.form
      this.form.id = this.id ? this.id : 0
      result = await RestApi.postData(tradeTariffServiceBaseUrl, bilateralTradeManagementStore, formData)
      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('mutateCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-form')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    getEditInfo () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    }
  }
}
</script>
