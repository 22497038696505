<template>
  <b-container fluid>
       <b-row style="line-height: 20px">
            <template>
                <b-row>
                    <table class="table table-bordered">
                    <tr><th>{{ $t('tradeTariffApp.targeted_country')}}</th><td>: {{ getCountryName(items.targeted_country) }} </td></tr>
                    <tr><th>{{ $t('tradeTariffApp.associated_country')}}</th><td>: {{ getCountryName(items.associated_country) }} </td></tr>
                    <tr><th>{{ $t('tradeTariffApp.total_export')}}</th><td>: {{ $n(items.total_export) }} </td></tr>
                    <tr><th>{{ $t('tradeTariffApp.total_import')}}</th><td>: {{ $n(items.total_import) }} </td></tr>
                    <tr><th>{{ $t('tradeTariffApp.bilateral_trade_details')}}</th><td>: {{ items.bilateral_trade_details }} </td></tr>
                    </table>
                </b-row>
            </template>
    </b-row>
  </b-container>
</template>
<script>
import { tradeTariffServiceBaseUrl } from '@/config/api_config'

export default {
    props: ['items'],
    components: {
    },
    created () {
    },
    mounted () {
    },
    data () {
        return {
            tradeTariffServiceBaseUrl: tradeTariffServiceBaseUrl,
            ports: [],
            seaPortsList: [],
            landPortsList: [],
            airPortsList: []
        }
    },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        }
    },
    methods: {
        getRegionName (id) {
            const cateObj = this.$store.state.TradeTariffService.commonObj.regionList.find(item => item.value === parseInt(id))
            if (cateObj !== undefined) {
                if (this.$i18n.locale === 'bn') {
                    return cateObj.text_bn
                } else {
                    return cateObj.text_en
                }
            } else {
            return ''
            }
        },
        getCurrentSignatoriesName (id) {
            const cateObj = this.$store.state.TradeTariffService.commonObj.tradeTariffCountryList.find(item => item.value === parseInt(id))
            if (cateObj !== undefined) {
                if (this.$i18n.locale === 'bn') {
                    return cateObj.text_bn
                } else {
                    return cateObj.text_en
                }
            } else {
            return ''
            }
        },
        getCoverageOfAgreementName (id) {
            const cateObj = this.$store.state.TradeTariffService.commonObj.coverageOfAgreementList.find(item => item.value === parseInt(id))
            if (cateObj !== undefined) {
                if (this.$i18n.locale === 'bn') {
                    return cateObj.text_bn
                } else {
                    return cateObj.text_en
                }
            } else {
            return ''
            }
        },
        getStatusOfAgreementName (id) {
            const cateObj = this.$store.state.TradeTariffService.commonObj.statusOfAgreementList.find(item => item.value === parseInt(id))
            if (cateObj !== undefined) {
                if (this.$i18n.locale === 'bn') {
                    return cateObj.text_bn
                } else {
                    return cateObj.text_en
                }
            } else {
            return ''
            }
        },
        getCountryName (id) {
            const obj = this.$store.state.TradeTariffService.commonObj.tradeTariffCountryList.find(item => item.value === parseInt(id))
            if (obj) {
                return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
            } else {
                return ''
            }
        },
        getCapitalName (id) {
            const obj = this.$store.state.TradeTariffService.commonObj.capitalInfoList.find(item => item.value === parseInt(id))
            if (obj) {
                return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
            } else {
                return ''
            }
        },
        getAirPortName (id) {
            var obj = this.ports
            if (obj) {
                obj = obj.find(item => item.id === parseInt(id))
                if (obj) {
                    return this.$i18n.locale === 'en' ? obj.port_name_en : obj.port_name_bn
                } else {
                    return ''
                }
            } else {
                return ''
            }
        },
        getLandPortName (id) {
            var obj = this.ports
            if (obj) {
                obj = obj.find(item => item.id === parseInt(id))
                if (obj) {
                    return this.$i18n.locale === 'en' ? obj.port_name_en : obj.port_name_bn
                } else {
                    return ''
                }
            } else {
                return ''
            }
        },
        getProductName (id) {
            const obj = this.$store.state.TradeTariffService.commonObj.productList.find(item => item.status === 1 && item.value === parseInt(id))
            if (obj) {
                return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
            } else {
                return ''
            }
        }
    }
}

</script>
